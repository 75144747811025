// import "./App.css";
import axios from "axios";
import { Component, createRef } from "react";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      page: 1,
      search: "",
      waiting: false,
      end: false,
      lecturer: [],
      topic: [],
    };
    this.loadMore = createRef();
    this.postsScroll = createRef();
  }

  componentDidMount() {
    this.getData();
  }

  getMeta = async (id, type) => {
    const typeArray = this.state[type];

    if (!typeArray[id]) {
      await axios
        .get(`${process.env.REACT_APP_BASE}/${type}/${id}`, {})
        .then((res) => {
          typeArray[res.data.id] = res.data;
        })
        .catch((err) => {});
    }
  };

  getData = async (page = 1, search = "", posts = []) => {
    this.setState({ waiting: true });

    let query = "";
    let filter = "";

    if (this.props.params) {
      for (const key in this.props.params) {
        const value = this.props.params[key];
        query += `&${key}=${value}`;
      }
    }

    if (this.props.custom) {
      if (!this.props.custom.custom_lectures_variants.includes("custom")) {
        const customType = this.props.custom.custom_lectures_variants.replace(
          "_lectures",
          ""
        );
        const customTerms =
          customType === "topic"
            ? this.props.custom.lectures_from_specific_topic.join(",")
            : this.props.custom.lectures_from_specific_lecturer;

        filter = `&${customType}=${customTerms}`;
      } else {
        const customIds = this.props.custom.custom_lectures_list_object;

        customIds.forEach((id) => {
          filter += `&include[]=${id}`;
        });
      }

      if (this.props.custom.show_only_featured_lectures) {
        query += `&meta_key=featured&meta_value=1`;
      }
    }

    await axios
      .get(
        `${process.env.REACT_APP_BASE}/${this.props.type}?page=${page}&search=${search}${query}${filter}`,
        {}
      )
      .then(async (res) => {
        if (this.props.type === "library" && res.data.length) {
          for await (const post of res.data) {
            if (post.lecturer) {
              await this.getMeta(post.lecturer, "lecturer");
            }

            if (post.topic) {
              for await (const topic of post.topic) {
                await this.getMeta(topic, "topic");
              }
            }
          }
        }

        posts.push(...res.data);
        this.setState({ posts });
        this.setState({ page });
        this.setState({ waiting: false });

        if (res.data.length < 10) {
          this.setState({ end: true });
        }
      })
      .catch((err) => {
        this.setState({ end: true });
        this.setState({ waiting: false });
        console.log(err);
      });
  };

  isInViewport = (parent, el) => {
    const parentRect = parent.getBoundingClientRect();
    const rect = el.getBoundingClientRect();
    return rect.bottom <= parentRect.bottom;
  };

  handleMore = async () => {
    if (
      !this.state.end &&
      this.isInViewport(this.postsScroll.current, this.loadMore.current) &&
      !this.state.waiting
    ) {
      const page = this.state.page + 1;
      this.getData(page, this.state.search, this.state.posts);
    }
  };

  setSearch = async (e) => {
    const search = e.target.value;

    if (!this.state.waiting) {
      this.postsScroll.current.scrollTop = 0;
      this.setState({ posts: [] });
      this.setState({ search });
      this.setState({ page: 1 });
      this.setState({ end: false });
      this.getData(1, search);
    }
  };

  render() {
    const border = this.props.border ? this.props.border : "blue";

    return (
      <div className="yasw-libraryBlock">
        <header className={`yasw-libraryBlock__header border-${border}`}>
          <h4 className="yasw-libraryBlock__header-title">
            {this.props.title}
          </h4>
          <div className="yasw-libraryBlock__header-search">
            <label>
              <input
                type="text"
                className="yasw-libraryBlock__header-searchField"
                placeholder={this.props.search}
                onKeyUp={this.setSearch}
              />
            </label>
          </div>
        </header>
        <div
          className="yasw-libraryBlock__list"
          ref={this.postsScroll}
          onScroll={this.handleMore}
        >
          {this.state.posts && this.state.posts.length ? (
            <>
              {this.state.posts.map((post) => {
                const title = post.title ? post.title.rendered : post.name;
                const link = post.link ? post.link : "";
                const lecturer = this.state.lecturer[post.lecturer]
                  ? this.state.lecturer[post.lecturer]
                  : "#";
                const audio = post.meta.lecture_audio
                  ? post.meta.lecture_audio
                  : post.meta.lecture_audio_link
                  ? post.meta.lecture_audio_link
                  : "#";
                const audioLink = post.meta.lecture_audio_link
                  ? post.meta.lecture_audio_link
                  : "";

                return (
                  <div className="yasw-libraryBlock__listItem">
                    <div className="yasw-libraryBlock__listItem-content">
                      {this.props.type === "library" ? (
                        <>
                          <h5
                            className="yasw-libraryBlock__listItem-title"
                            dangerouslySetInnerHTML={{ __html: title }}
                          />
                          <div className="yasw-libraryBlock__listItem-text">
                            <p>
                              {post.lecturer ? (
                                <>
                                  by{" "}
                                  <span>
                                    <a
                                      className="yasw-libraryBlock__listItem-lecturer"
                                      href={lecturer.link}
                                    >
                                      {lecturer.name}
                                    </a>
                                  </span>{" "}
                                </>
                              ) : null}
                              {post.topic ? (
                                <>
                                  in{" "}
                                  {post.topic.map((postTopic) => {
                                    const topic = this.state.topic[postTopic]
                                      ? this.state.topic[postTopic]
                                      : "";

                                    return (
                                      <span>
                                        <a
                                          className="yasw-libraryBlock__listItem-topic"
                                          href={topic.link}
                                        >
                                          {topic.name}
                                        </a>
                                      </span>
                                    );
                                  })}
                                </>
                              ) : null}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.props.type === "lecturer" ? (
                            <div class="yasw-libraryBlock__listItem-text">
                              <p>
                                <a href={link}>{title}</a>
                              </p>
                            </div>
                          ) : (
                            <h5 class="yasw-libraryBlock__listItem-title ">
                              <a
                                class="yasw-libraryBlock__listItem-topic"
                                href={link}
                              >
                                {title}
                              </a>
                            </h5>
                          )}
                        </>
                      )}
                    </div>

                    {this.props.type === "library" ? (
                      <div className="yasw-libraryBlock__listItem-controls">
                        <a
                          href={audio}
                          className="yasw-libraryBlock__listItem-audio"
                          target="_blank"
                        ></a>

                        <a
                          href="#"
                          className="yasw-libraryBlock__listItem-bookmark"
                        ></a>

                        <a
                          href={audioLink}
                          className="yasw-libraryBlock__listItem-download"
                          target="_blank"
                        ></a>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </>
          ) : null}
          {!this.state.end ? (
            <div className="yasw-libraryBlock__listItem" ref={this.loadMore}>
              <div className="yasw-libraryBlock__listItem-text">
                <p>Loading...</p>{" "}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default App;
