import React from "react";
import ReactDOM from "react-dom";
import "./frontend.min.css";
import "./main.css";
import "./style.css";
import "./style.min.css";
import "./template-library.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (document.getElementById("home-root")) {
  ReactDOM.render(
    <React.StrictMode>
      <div class="section-library__blocks home-library-blocks">
        <div class="yasw-libraryBlock">
          <App
            title="FEATURED SHIURIM"
            search="Search"
            type="library"
            params={{ meta_key: "featured", meta_value: 1 }}
          />
        </div>
        <div class="yasw-libraryBlock">
          <App title="TOPICS" search="Search" type="topic" border="green" />
        </div>
        <div class="yasw-libraryBlock">
          <App title="MAGIDEI SHIUR" search="Search" type="lecturer" />
        </div>
      </div>
    </React.StrictMode>,
    document.getElementById("home-root")
  );
}

if (document.getElementById("library-root")) {
  const rootEl = document.getElementById("library-root");
  const custom = rootEl.dataset.custom
    ? JSON.parse(rootEl.dataset.custom)
    : null;
  const customTitle = custom ? custom.custom_lectures_title : null;

  ReactDOM.render(
    <React.StrictMode>
      <div class="section-library__blocks library-blocks-inner">
        <div className="library-blocks-col">
          <div class="yasw-libraryBlock">
            <App
              title="FEATURED SHIURIM"
              search="Search"
              type="library"
              params={{ meta_key: "featured", meta_value: 1 }}
            />
          </div>
        </div>
        <div className="library-blocks-col library-blocks-col-double">
          <div class="yasw-libraryBlock">
            <App title="TOPICS" search="Search" type="topic" border="green" />
          </div>
          <div class="yasw-libraryBlock">
            <App title="MAGIDEI SHIUR" search="Search" type="lecturer" />
          </div>
        </div>

        <div className="library-blocks-col">
          <div class="yasw-libraryBlock">
            <App
              title="RECENT SHIURIM"
              search="Search"
              type="library"
              border="red"
            />
          </div>
        </div>
        <div className="library-blocks-col">
          <div class="yasw-libraryBlock">
            <App
              title={customTitle}
              search="Search"
              type="library"
              custom={custom}
              border="dark"
            />
          </div>
        </div>
      </div>
    </React.StrictMode>,
    document.getElementById("library-root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
